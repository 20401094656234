
:root{
    --main-color:#720004;
    --button-color:#a50000;
}
html, body{margin:0; padding:0}

body{font-family: 'Source Sans Pro', sans-serif;color: var(--main-color);
    background-color: rgba(209,195,146,.3);
}
.heading-color{
    color: #D1C392 !important;
}
.header-login h2{
    color: #D1C392 !important;
}
/* background-color: #89000A;background: url('http://www.yourgsv.org/design/bg_updated.jpg') no-repeat;*/
.form{width:100%; margin:0 auto; min-height: 90vh}
.header{height:80px; background: var(--main-color)}
.header-login{
    display: flex;
    justify-content: center;
    align-items: center;
    height:80px; background: var(--main-color)}
.header h2{height:44px; line-height:44px; color:#D1C392; text-align:center}
.login{padding: 20px 20px 20px 20px; width: 480px; margin-left: auto; margin-right: auto; margin-top: 20px; margin-bottom: 20px; background-color: #FFFFFF; min-height: 100%}
.un{
    height: 42px !important;
}
.login span.un{width:10%; text-align:center; color:#89000A; border-radius:3px 0 0 3px; height: 42px;}
.text{background:#eee; width:90%; font-size: 1.3em; border-radius:0 3px 3px 0; border:none; outline:none; color:#353535; font-family: 'Source Sans Pro', sans-serif}
.text,.login span.un{display:inline-block; vertical-align:top; height:40px; line-height:40px; background:#eee;}
ul{
    padding: 0;
}
.btn{height:40px; border:none; background:var(--button-color); width:100%; outline:none; font-family: 'Source Sans Pro', sans-serif; font-size:20px; font-weight:bold; color:#eee; border-bottom:solid 3px #720007; border-radius:3px; -webkit-appearance: none; cursor:pointer}
ul li{height:40px; margin:15px 0; list-style:none}
.span{display:table; width:100%; font-size:14px;}
.ch{display:inline-block; width:50%; color:#fff}
.ch a{color:#fff; text-decoration:none}
.ch:nth-child(2){text-align:right}
/*social*/
.social{height:30px; line-height:30px; display:table; width:100%}
.social div{display:inline-block; width:42%; color:#eee; font-size:12px; text-align:center; border-radius:3px}
.social div i.fa{font-size:16px; line-height:30px}
.fb{background:#3B5A9A; border-bottom:solid 3px #036}
.tw{background:#2CA8D2; margin-left:16%; border-bottom:solid 3px #3399CC}
/*bottom*/
.sign{width:90%; padding:0 5%; height:50px; display:table; background: var(--main-color)}
.sign div{display:inline-block; width:50%; line-height:50px; color:#fff; font-size:14px}
.up{text-align:right}
.up a{display:block; background:var(--button-color); text-align:center; height:35px; line-height:35px; width:50%; font-size:16px; text-decoration:none; color:#eee; border-bottom:solid 3px #720007; border-radius:3px; font-weight:bold; margin-left:50%}
@media(max-width:480px){ .form{width:100%}}
#login_error{background: #FFC200; color: #80561b; text-align:center;}
#login_nconfirm{background: #0BD600; color: #5c5c5c; text-align:center}
#user{
    position: relative;
    width:97%;
    text-align:right;
    margin:0 auto;
    padding: 3px 5px 0 0;
    color: #ccc;
}
#mygsv_icon{
    width: 75px;
    height: 65px;
}
a:link {
    color: var(--main-color);
}
a.button-icon{
    color: #D1C392 !important;
}
a:visited {
    color: var(--main-color);
}
a:hover {
    color: var(--main-color);
}
a:active {
    color: var(--main-color);
}
.up a:link {
    color: #fff;
}
.up a:visited {
    color: #fff;
}
.up a:hover {
    color: #fff;
}
.up a:active {
    color: #fff;
}
select:required:invalid {
    color: gray;
}
option[value=""][disabled] {
    display: none;
}
option {
    color: black;
}
#copyfooter{
    text-align: center;
    color: #8c000b;
    font-size: 0.6em;
    padding-top: 10px;
    padding-bottom: 10px;
}
#copyfooter a:link {
    color: #8c000b;
    text-decoration: none;
}
#copyfooter a:visited {
    color: #8c000b;
    text-decoration: none;
}
#copyfooter a:hover {
    color: #8c000b;
    text-decoration: none;
}
#copyfooter a:active {
    color: #8c000b;
    text-decoration: none;
}
#appoffer {
    z-index: 1;
}
#appoffer a:link {
    color: #000;
    text-decoration: none;
}
#appoffer a:visited {
    color: #000;
    text-decoration: none;
}
#appoffer a:hover {
    color: #000;
}
#appoffer a:active {
    color: #000;
}
.loadingoverlay{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    cursor: pointer;
}
.loadingicon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 6px solid #EEEEEE;
    border-top-color: #BFBFBF;
    border-bottom-color: #BFBFBF;
    -webkit-animation: loadingicon 2.5s infinite ease-in-out;
    animation: loadingicon 2.5s infinite ease-in-out;
    position: fixed;
    top:-100%; right:-100%; left:-100%; bottom:-100%;
    margin: auto;
    z-index: 10000;
}

.loadingicon-icons {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 6px solid #EEEEEE;
    border-top-color: #BFBFBF;
    border-bottom-color: #BFBFBF;
    -webkit-animation: loadingicon 2.5s infinite ease-in-out;
    animation: loadingicon 2.5s infinite ease-in-out;
    margin: auto;
    z-index: 10000;
}

@-webkit-keyframes loadingicon {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(540deg);
        transform: rotate(540deg);
    }
}

@keyframes loadingicon {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(540deg);
        transform: rotate(540deg);
    }
}
#photoEditor{
    color: #000;
}
#photoEditor a:link {
    color: var(--main-color);
}
/* visited link */
#photoEditor a:visited {
    color: var(--main-color);
}
/* mouse over link */
#photoEditor a:hover {
    color: var(--main-color);
}
/* selected link */
#photoEditor a:active {
    color: var(--main-color);
}
.flex{
    display: flex;
    justify-content: space-between;
}
.flex-wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.flex-even{
    display: flex;
    justify-content: space-evenly;
}
.flex-beside{
    display: flex;
    align-items: flex-start;
}
.flex-photos{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.bottom-padding{
    padding-bottom: 20px;
}
.top-bottom-padding{
    padding-top: 5px;
    padding-bottom: 5px;
}
.top-bottom-padding-large{
    padding-top: 20px;
    padding-bottom: 20px;
}
.padding-content{
    padding-left: 15px;
    padding-right: 15px;
}
.button-container{
    width: 30%;
    margin: 15px 7px 10px 7px;
}
.svg-style{
    fill:#231f20;
    opacity:0.30000000000000004;
}
.logout-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--main-color)
}
.trash-container{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.back-arrow-container{
    display: flex;
    align-items: center;
}
.gallery-item{
    text-align: center;
    width: 175px;
    margin: 15px 10px 10px 0;
}


.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.box {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    height: fit-content;
    max-height: 100vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
}

.close-icon {
    content: 'x';
    color: black;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10000;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    font-size: 20px;
}
.flex-filefolders{
    width: 100%;
    padding-top: 15px;
    align-content: center;
}
.folder-padding{
    padding-left: 10px;
}
.text-left{
    text-align: left;
}
.title{
    color: black;
    padding-top: 10px;
}
.img-max-height{
    max-height: 70vh;
}
.user-display{
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
}

.user-display-full{
    display: flex;
    justify-content: space-between;
    padding-top: 15px;

}
.heading-display{
    padding-top: 5px;
    padding-right: 5px;
    padding-left: 5px;
    position: relative;
}
.align-text-left{
    text-align: left;
}
.flex-baselign{
    display: flex;
    align-items: baseline;
}
input[type="date"]:before {
    content: attr(placeholder) !important;
    color: #aaa;
    margin-right: 0.5em;
}
input[type="date"]:focus:before,
input[type="date"]:valid:before {
    content: "";
}

.photo-gallery-photo{
    width:152px;
    height:152px;
    object-fit:cover;
}
.cursor-pointer{
    cursor: pointer;
}
.black-text{
    color: black;
}

.my-iframe{
    position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;
}
.errorf{
    font-size: 6.3em;
    padding-top: 25px;
}
.padding-left{
    padding-left: 10px;
}
.flex-space-between{
    display: flex;
    justify-content: space-between;
}
.max-width{
    max-width: 500px;
}
.new-button{
    display: inline-block;
}
.new-button-size{
    width: 125px;
    cursor: pointer;
}
